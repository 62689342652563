<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 v-if="surveytype === 'behavioral'" class="fw-bolder m-0">
              Email A Behavioral Survey
            </h3>
            <h3 v-if="surveytype === 'job'" class="fw-bolder m-0">
              Email A JOB Survey
            </h3>
          </div>
          <!--end::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <a
              href="#"
              class="btn btn-sm btn-flex btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal_new_respondent"
            >
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/general/gen035.svg" />
              </span>
              Create new recipient</a
            >
          </div>
          <!--end::Card toolbar-->
          <!--begin::Card body-->
        </div>
        <!--end::Card body-->
        <!--begin::Card header-->

        <!--begin::Autocomplete -->
        <div class="card-body pt-0" v-if="isDataReady">
          <!--begin::Input group-->
          <div
            v-if="surveytype === 'job'"
            class="d-flex flex-column mb-8 fv-row"
          >
            <!--begin::Label-->
            <label class="d-flex align-items-center fs-6 fw-bold mb-2">
              <span class="required">Survey Job Title</span>
            </label>
            <!--end::Label-->

            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="job title"
              name="jobTitle"
              autocomplete="off"
              v-model="emailSurveyRequest.surveyJobTitle"
            />
          </div>
          <!--end::Input group-->
          <AutoCompleteRespLookup
            :userRole="currentUser.role"
            :userId="currentUser.id"
          >
          </AutoCompleteRespLookup>
          <AutoComplete
            :dataList="respondents"
            :userRole="currentUser.role"
            @respondent-selected="onRespondentSelected"
          ></AutoComplete>
          <div v-if="emailRespondentList.length > 0" class="card-body pt-0">
            <!--begin:: Flex Container-->
            <div
              v-for="item in emailRespondentList"
              :key="item.aspNetUserId"
              id="incline-flex-table"
              class="d-flex flex-row flex-wrap pb-5 pt-3"
            >
              <div
                class="
                  d-flex
                  flex-column flex-sm-column flex-fill flex-wrap
                  px-5
                "
              >
                <p class="incline-flex-table-th mb-0">Name</p>
                <p class="incline-flex-table-td mb-0">
                  <strong
                    >{{ item.lastName }},
                    {{ item.firstName }}
                  </strong>
                </p>
              </div>
              <div class="d-flex flex-column flex-fill flex-wrap">
                <p class="incline-flex-table-th">Email</p>
                <p class="incline-flex-table-td">
                  {{ item.email }}
                </p>
              </div>
              <div class="d-flex flex-column flex-fill flex-wrap">
                <p class="incline-flex-table-th">Job Title</p>
                <p class="incline-flex-table-td">
                  {{ item.jobTitle }}
                </p>
              </div>
              <div class="d-flex flex-column flex-fill flex-wrap">
                <p class="incline-flex-table-th">Employment</p>
                <p class="incline-flex-table-td">{{ item.hireStatus }}</p>
              </div>
              <div class="d-flex flex-column flex-fill flex-wrap">
                <p class="incline-flex-table-th">Action</p>
                <div v-if="item.clients.length > 0" class="d-flex flex-column">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary mb-2"
                    v-for="(client, index) in item.clients"
                    :key="index"
                    @click="
                      checkIfJobTitleExists(
                        item.firstName,
                        item.lastName,
                        item.email,
                        item.jobTitle,
                        item.aspNetUserId,
                        client.clientId,
                        client.clientName,
                        client.clientLocationId,
                        client.clientLocationName
                      )
                    "
                  >
                    <span class="svg-icon svg-icon-3 ms-0 me-2">
                      <inline-svg
                        src="/media/icons/duotune/communication/com002.svg"
                      />
                    </span>

                    <span
                      ><strong>Click To Send Email</strong> ({{
                        client.clientLocationName
                      }})</span
                    >
                  </button>
                </div>
                <div v-else>
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    @click="
                      checkIfJobTitleExists(
                        item.firstName,
                        item.lastName,
                        item.email,
                        item.jobTitle,
                        item.aspNetUserId,
                        0,
                        '',
                        0,
                        ''
                      )
                    "
                  >
                    <span class="svg-icon svg-icon-3 ms-0 me-2">
                      <inline-svg
                        src="/media/icons/duotune/communication/com002.svg"
                      />
                    </span>
                    <strong>Email Survey</strong>
                  </button>
                </div>
              </div>
            </div>
            <!--end::Flex Container-->
          </div>
        </div>
        <div v-else>
          <TableSkeleton></TableSkeleton>
        </div>
        <!--end::Autocompleted -->
      </div>
    </div>
  </div>
  <NewEmailRespondentModal
    v-if="isLicensedDistributorAssocIdReady"
    @new-respondent-entered="onRespondentSelected"
    :displayModal="displayModal"
    :userRole="currentUser.role"
    :licensedDistributorId="0"
    :licensedDistAssocId="licensedDistributorAssocId"
    :clientId="0"
  ></NewEmailRespondentModal>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  computed,
  watch,
  ref,
  reactive,
  toRefs,
} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  Respondent,
  RespondentListRequestDto,
} from "../../core/types/RespondentTypes";
import { EmailSurveyRequestDto } from "../../core/types/EmailTypes";
import NewEmailRespondentModal from "@/components/modals/forms/NewEmailRespondentModal.vue";
import AutoComplete from "@/components/lookup/autocompleteRespondents.vue";
import AutoCompleteRespLookup from "@/components/lookup/autocompleteRespLookup.vue";

export default defineComponent({
  name: "lda-email-survey",
  props: ["surveytype"],
  components: {
    Field,
    TableSkeleton,
    NewEmailRespondentModal,
    AutoComplete,
    AutoCompleteRespLookup,
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const emailRespondentList = ref([] as Respondent[]);
    const licensedDistributorAssocId = computed(() => {
      return store.getters.getLDAssociatesLicensedDistributorId;
    });
    const isLicensedDistributorAssocIdReady = ref(false);

    const emailSurveyRequest = ref<EmailSurveyRequestDto>({
      recipientFirstName: "",
      recipientLastName: "",
      recipientEmail: "",
      recipientAspNetUserId: "",
      clientId: 0,
      clientName: "",
      clientLocationId: 0,
      clientLocationName: "",
      sentByName: "",
      sentByAspNetUserId: "",
      surveyType: props.surveytype,
      jobTitle: "",
      surveyJobTitle: "",
    });

    const respondentRequest = ref<RespondentListRequestDto>({
      currentUserRole: currentUser.role,
      currentUserId: currentUser.id,
      licensedDistId: 0,
      licensedAssocDistId: 0,
      clientId: 0,
    });

    const respondents = computed(() => {
      return store.getters.getRespondents;
    });

    const initialRespondent: Respondent = {
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      hireStatus: "",
      jobTitle: "",
      clients: [],
    };

    const newEmailRecipient = reactive<Respondent>({
      ...initialRespondent,
    });

    onMounted(() => {
      setCurrentPageTitle("Email A Survey");
      setCurrentPageBreadcrumbs("Email A Survey", ["Surveys"]);
    });

    const getRespondents = () => {
      respondentRequest.value.licensedDistId = licensedDistributorAssocId.value;

      store
        .dispatch(Actions.GET_RESPONDENT_LIST, respondentRequest.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "LDA: There was a problem retrieving respondents.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    if (licensedDistributorAssocId.value) {
      isLicensedDistributorAssocIdReady.value = true;
      getRespondents();
    } else {
      store
        .dispatch(
          Actions.GET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID,
          currentUser.id
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving licensed distributor id.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    /* Autocomplete */

    const onRespondentSelected = (
      firstname,
      lastname,
      email,
      id,
      hireStatus,
      jobTitle,
      clients
    ) => {
      newEmailRecipient.aspNetUserId = id;
      newEmailRecipient.firstName = firstname;
      newEmailRecipient.lastName = lastname;
      newEmailRecipient.email = email;
      newEmailRecipient.hireStatus = hireStatus;
      newEmailRecipient.jobTitle = jobTitle;
      newEmailRecipient.clients = clients;

      emailRespondentList.value.push({
        ...newEmailRecipient,
      });

      //store.commit(Mutations.ADD_RECIPIENT_TO_EMAIL_LIST, newEmailRecipient);
    };

    function resetNewRecipient() {
      Object.assign(newEmailRecipient, initialRespondent);
      emailSurveyRequest.value.recipientFirstName = "";
      emailSurveyRequest.value.recipientLastName = "";
      emailSurveyRequest.value.recipientEmail = "";
      emailSurveyRequest.value.recipientAspNetUserId = "";
      emailSurveyRequest.value.clientId = 0;
      emailSurveyRequest.value.clientName = "";
      emailSurveyRequest.value.clientLocationId = 0;
      emailSurveyRequest.value.clientLocationName = "";
      emailSurveyRequest.value.sentByName = "";
      emailSurveyRequest.value.sentByAspNetUserId = "";
      emailSurveyRequest.value.surveyType = props.surveytype;
      emailSurveyRequest.value.jobTitle = "";
    }

    function checkIfJobTitleExists(
      firstname,
      lastname,
      email,
      jobTitle,
      id,
      clientid,
      clientname,
      clientlocationid,
      clientlocationname
    ) {
      if (
        props.surveytype === "job" &&
        emailSurveyRequest.value.surveyJobTitle === ""
      ) {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "You must enter a Survey Job Title first.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        emailRespondent(
          firstname,
          lastname,
          email,
          jobTitle,
          id,
          clientid,
          clientname,
          clientlocationid,
          clientlocationname
        );
      }
    }

    /* End Autocompleted */

    const emailRespondent = (
      firstname,
      lastname,
      email,
      jobTitle,
      id,
      clientid,
      clientname,
      clientlocationid,
      clientlocationname
    ) => {
      emailSurveyRequest.value.recipientFirstName = firstname;
      emailSurveyRequest.value.recipientLastName = lastname;
      emailSurveyRequest.value.recipientEmail = email;
      emailSurveyRequest.value.jobTitle = jobTitle;
      emailSurveyRequest.value.recipientAspNetUserId = id;
      emailSurveyRequest.value.clientId = clientid;
      emailSurveyRequest.value.clientName = clientname;
      emailSurveyRequest.value.clientLocationId = clientlocationid;
      emailSurveyRequest.value.clientLocationName = clientlocationname;
      emailSurveyRequest.value.sentByName =
        currentUser.firstName + " " + currentUser.lastName;
      emailSurveyRequest.value.sentByAspNetUserId = currentUser.id;

      store
        .dispatch(Actions.EMAIL_SURVEY, emailSurveyRequest.value)
        .then(() => {
          Swal.fire({
            text: "Success. The email was sent.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function (data) {
            //
            let objIndex = emailRespondentList.value.findIndex(
              (obj) => obj.aspNetUserId === data.recipientAspNetUserId
            );
            emailRespondentList.value.splice(objIndex, 1);
            resetNewRecipient();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    watch(licensedDistributorAssocId, () => {
      isLicensedDistributorAssocIdReady.value = true;
      getRespondents();
    });

    watch(respondents, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      licensedDistributorAssocId,
      isLicensedDistributorAssocIdReady,
      emailRespondentList,
      displayModal,
      isDataReady,
      emailSurveyRequest,
      respondentRequest,
      ...toRefs(newEmailRecipient),
      initialRespondent,
      respondents,
      getRespondents,
      emailRespondent,
      onRespondentSelected,
      resetNewRecipient,
      checkIfJobTitleExists,
    };
  },
});
</script>
