<template>
  <div class="mt-6">
    <!--begin::Label-->
    <label for="clientSearch" class="form-label required">
      Loookup Enter Last Name of Recipient</label
    >
    <!--end::Label-->

    <!--begin::Input-->
    <div class="incline-autocomplete">
      <input
        type="text"
        id="search"
        placeholder="Type Last Name of Recipient..."
        v-model="search"
        class="form-control"
        autocomplete="off"
      />
      <!--end::Input-->
      <ul class="incline-autocomplete-results">
        <li class="incline-autocomplete-result">
          Showing { searchClients.length } of { clientList.length } results
        </li>
        <!-- <li
          v-for="item in searchClients"
          :key="item.aspNetUserId"
          @click="selectRecipient(item)"
          class="incline-autocomplete-result"
        >
          {{ item.lastName }}, {{ item.firstName }}
        </li> -->
      </ul>
      <!--<p v-if="selectedRespondent">
        You have selected:
        <span>{{ selectedRespondent }}</span>
      </p>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  Respondent,
  RespondentListRequestDto,
} from "../../core/types/RespondentTypes";

export default defineComponent({
  name: "incline-respondent-lookup",
  props: {
    userRole: {
      type: [String],
      default: "",
    },
    userId: {
      type: [String],
      default: "",
    },
  },
  components: {},
  emits: ["respondent-selected"],
  setup(props, { emit }) {
    const store = useStore();
    let search = ref("");

    const respondentRequest = ref<RespondentListRequestDto>({
      currentUserRole: props.userRole,
      currentUserId: props.userId,
      licensedDistId: 0,
      licensedAssocDistId: 0,
      clientId: 0,
    });

    const selectedRespondent = ref<Respondent>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      hireStatus: "",
      jobTitle: "",
      clients: [],
    });
    const selectRecipient = (client) => {
      selectedRespondent.value = client;
      search.value = "";
      emit(
        "respondent-selected",
        selectedRespondent.value.firstName,
        selectedRespondent.value.lastName,
        selectedRespondent.value.email,
        selectedRespondent.value.aspNetUserId,
        selectedRespondent.value.hireStatus,
        selectedRespondent.value.jobTitle,
        selectedRespondent.value.clients
      );
    };
    watch(search, (newValue, oldValue) => {
      if (newValue.length > 2) {
        console.log("greater than 2");
        respondentRequest.value.currentUserId = props.userId;
        respondentRequest.value.currentUserRole = props.userRole;

        store
          .dispatch(Actions.POST_LOOKUP_RESPONDENTS, respondentRequest.value)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "LDA: There was a problem retrieving respondents.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    });

    return {
      respondentRequest,
      selectedRespondent,
      selectRecipient,
      search,
    };
  },
});
</script>
